import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./userCalendar.css";
import { db } from "../Firebase/firebase";
import {
	collection,
	addDoc,
	getDocs,
	deleteDoc,
	doc,
} from "firebase/firestore";

// //TODO users will need to be pulled from the database
// const users = [
// 	{ id: 1, name: "User 1" },
// 	{ id: 2, name: "User 2" },
// 	{ id: 3, name: "User 3" },
// 	{ id: 4, name: "User 4" },
// 	{ id: 5, name: "User 5" },
// 	// Add more users as needed
// ];

export default function Calendar() {
	const [modal, setModal] = useState(false);
	const [events, setEvents] = useState([]);
	const [title, setTitle] = useState("");
	const [start, setStart] = useState("");
	const [end, setEnd] = useState("");
	const [selectedEvent, setSelectedEvent] = useState(null);
	const calendarRef = useRef(null);
	const [users, setUsers] = useState([]);
	const [currentUser, setCurrentUser] = useState(null);
	const [allUsers, setAllUsers] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState(""); //store users for meeting attendance

	// Fetch availability and events from Firestore
	useEffect(() => {
		const getEvents = async () => {
			try {
				const availabilitiesData = await getDocs(
					collection(db, "availabilities")
				);
				const availabilities = availabilitiesData.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
					color: "green",
					extendedProps: {
						type: "availability",
					},
				}));

				const meetingsData = await getDocs(collection(db, "meetings"));
				const meetings = meetingsData.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
					color: "blue",
					extendedProps: {
						type: "meeting",
					},
				}));

				setEvents([...availabilities, ...meetings]);
			} catch (error) {
				console.error("Error fetching events:", error);
			}
		};
		getEvents();
	}, []);

	// Get users
	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const usersData = await getDocs(collection(db, "users"));
				const usersList = usersData.docs.map((doc) => ({
					uid: doc.data().uid,
					name: `${doc.data().firstName} ${doc.data().lastName}`,
				  }));
				  setAllUsers(usersList);
			} catch (error) {
				console.error("Error fetching users: ", error);
			}
		};
		fetchUsers();
	}, []);

	// check if its within availability
	const isTimeSlotAvailable = (start, end) => {
		return events.some(
			(event) =>
				event.extendedProps.type === "availability" &&
				new Date(start) >= new Date(event.start) &&
				new Date(end) <= new Date(event.end)
		);
	};

	// Open modal
	const handleCreateEventClick = (selectInfo) => {
		const { startStr, endStr } = selectInfo;
		setSelectedEvent(null);
		setStart(startStr);
		setEnd(endStr);
		setSelectedUsers([]);
		setModal(true);
	};

	// Handle event submission and add to Firestore
	const handleSubmit = async (event) => {
		event.preventDefault();
		// Check if the selected time slot is available
		if (!isTimeSlotAvailable(start, end)) {
			window.alert("The selected time slot is not available. Please choose an available slot.");
			return;
		}

		const newEvent = {
			title,
			start,
			end,
			attendees: [currentUser.uid, ...selectedUsers], // Attendees for the meeting
		};
		//TODO add the event to calendars of the attendees
		try {
			// Save the new event to Firestore
			await addDoc(collection(db, "users", currentUser.uid, "meetings"), newEvent);
			selectedUsers.forEach(async (userId) => {
				await addDoc(collection(db, "users", userId, "meetings"), newEvent);
			});
			setEvents([
				...events,
				{
					...newEvent,
					color: "blue",
					extendedProps: {
						type: "meeting",
					},
				},
			]);
			alert("Meeting saved to database!");
			setModal(false);
		} catch (error) {
			console.error("Error saving meeting: ", error);
			alert("Failed to save meeting.");
		}
	};

	const handleEventClick = (clickInfo) => {
		if (clickInfo.event.extendedProps.type === "availability") {
			alert("You cannot modify or delete availability slots.");
			return;
		}
		setSelectedEvent(clickInfo.event);
		setModal(true); // Open the modal to show event details
	};

	// handle meeting deletion
	const handleDeleteEvent = async () => {
		if (!selectedEvent || selectedEvent.extendedProps.type === "availability")
			return;

		if (
			window.confirm(
				`Are you sure you want to delete the event '${selectedEvent.title}'?`
			)
		) {
			try {
				// Removes from firestore
				await deleteDoc(doc(db, "meetings", selectedEvent.id));

				// Remove locally
				setEvents(events.filter((event) => event.id !== selectedEvent.id));
				selectedEvent.remove(); // Remove event from calendar view

				alert("Event deleted successfully!");
				setModal(false);
			} catch (error) {
				console.error("Error deleting event: ", error);
				alert("Failed to delete the event.");
			}
		}
	};

	return (
		<div className="container">
			<div id="calendar">
				<FullCalendar
					ref={calendarRef}
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
					headerToolbar={{
						start: "prev,next createEventButton",
						center: "title",
						end: "today,dayGridMonth,timeGridWeek,timeGridDay",
						attendees: "attendees",
					}}
					customButtons={{
						createEventButton: {
							text: "Create Event",
							click: handleCreateEventClick, // open modal
						},
					}}
					initialView="dayGridMonth"
					events={events}
					eventClick={handleEventClick}
					selectable={true}
				/>

				{modal && (
					<div className="custom-modal">
						<div className="custom-modal-content">
							<span className="close" onClick={() => setModal(false)}>
								&times;
							</span>
							{selectedEvent ? (
								<div className="event-details">
									<h2 className="event-title">Event Details</h2>
									<p className="event-text">
										<strong>Title:</strong> {selectedEvent.title}
									</p>
									<p className="event-text">
										<strong>Start:</strong>{" "}
										{new Date(selectedEvent.start).toLocaleString()}
									</p>
									<p className="event-text">
										<strong>End:</strong>{" "}
										{new Date(selectedEvent.end).toLocaleString()}
									</p>

									<button className="delete-button" onClick={handleDeleteEvent}>
										Delete Event
									</button>
								</div>
							) : (
								<>
									<div className="form-group">
										<label htmlFor="meetingTitle">Meeting Title</label>
										<input
											type="text"
											id="meetingTitle"
											name="meetingTitle"
											className="form-control"
											placeholder="e.g. Lab Advisor Meeting"
											value={title}
											onChange={(e) => setTitle(e.target.value)}
										/>
									</div>

									<div className="form-group">
										<label htmlFor="start">Start</label>
										<input
											id="start"
											type="datetime-local"
											name="start"
											className="form-control"
											value={start}
											onChange={(e) => setStart(e.target.value)}
										/>
									</div>

									<div className="form-group">
										<label htmlFor="end">End</label>
										<input
											type="datetime-local"
											id="end"
											name="end"
											className="form-control"
											value={end}
											onChange={(e) => setEnd(e.target.value)}
										/>
									</div>
									<div className="form-group">
                    <label>Attendees</label>
                    <select
                      className="form-control"
                      multiple
                      value={selectedUsers}
                      onChange={(e) => {
                        const selectedOptions = Array.from(
                          e.target.selectedOptions,
                          (option) => option.value
                        );
                        setSelectedUsers(selectedOptions);
                      }}
                      style={{ height: "100px", overflowY: "scroll" }}
                    >
                      {allUsers
                        .map((user) => (
                          <option key={user.uid} value={user.uid}>
                            {user.name}
                          </option>
                        ))}
                    </select>
                  </div>

									<button className="btn" onClick={handleSubmit}>
										Save Event
									</button>
								</>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
