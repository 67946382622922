import { doc, collection, query, where, getDocs, limit, arrayUnion, updateDoc } from "firebase/firestore";
import { db } from "./Firebase/firebase";


export function createTeamCode(){
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890";
    let randomString = "";
    let length = 10;
    for (let i = 0; i < length; i++){
        randomString += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return randomString;
}

export async function handleTeamCodeDatabase(teamCode){
    const teamsRef = collection(db, "teams");
    const q = query(teamsRef, where("teamJoinCode", "==", teamCode), limit(1));
    let docQ = null;

    //temp user
    const user={
        email: "testAddEmail@csus.edu",
        username: "testAddUsername",
    }

    


    await getDocs(q).then((querySnapshot) => {
        if(!querySnapshot.empty){
            docQ = querySnapshot.docs[0];
            console.log("Document data:", docQ.data());
            console.log("Doc ID:", docQ.id);
            addToTeam(docQ);
        }
        else{
            console.log("Document does not exist");
            alert("Team Code Invalid");
        }
    })
    .catch((error) => {
        console.error('Error retrieving document:', error);
    });


    async function addToTeam(docQ){
        let isValidTeamCode = true;
        if(docQ != null){
            console.log("Doc exists, commencing add");
        }
        else{
            console.log("No document, cannot add to team");
            isValidTeamCode = false;
        }

        if(isValidTeamCode){
            console.log("Valid Team Code")
            await joinTeam(docQ).catch();
        }
    }

    async function joinTeam(docQ){
        const docRef = doc(db, "teams", docQ.id);
        let tempEmail = user.email.replace(".", "_");
        
        try{
        await updateDoc(docRef, {
            teamMemberEmail: arrayUnion(user.email),
            [`teamMemberName.${tempEmail}`]: user.username,
        });


        } catch(error) {
            console.error("Failed to update doc: ", error);
            alert("Failed to join team");
        }
    }
}

