import React, {useState} from "react";
import "./homepage.css";
import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence } from "firebase/auth";
import {validateField, emailExists} from './inputValidation';
import usernameInput from "./AssetsLoginSignup/usernameInput";

function LoginPortal() {

	//code to check for login credentials and moving to profile page
	const [inputFieldData, setInputFieldData] = useState({  // Holds the inputs
        email: '',
		password: ''
    });

	const [inputFieldErrors, SetInputFieldErrors] = useState({}); // Store any field errors

	const handleChange = (event) => { // Function updates the new/current inputs
        const { name, value } = event.target;
        setInputFieldData(prev => ({ ...prev, [name]: value }));
    };

	const auth = getAuth();

	const handleSubmit = async (event) => { // Function handles the submission to check if its valid
        event.preventDefault();
		let errors = {};
        let isFormValid = true;

		Object.keys(inputFieldData).forEach(key => { //Goes over inputs to check if filled
			if (!inputFieldData[key]) {
			  	errors[key] = '*This field is required';
			  	isFormValid = false;
			} 
			else { // Checks if validateField was good
			  	const fieldError = validateField(key, inputFieldData[key]);
			  	if (fieldError) {
					errors[key] = fieldError;
					isFormValid = false;
			  	} 
			}
		});

		// Check email exists
        if (!(await emailExists(inputFieldData.email))) {
			errors.email = '*Email Does Not Exist';
			isFormValid = false;
		}

		SetInputFieldErrors(errors);
        if (isFormValid) {
            console.log("Form is valid", inputFieldData);

            //login here
			setPersistence(auth, browserSessionPersistence)
  			.then(() => {
    			// Existing and future Auth states are now persisted in the current
    			// session only. Closing the window would clear any existing state even
    			// if a user forgets to sign out.
    			// ...
    			// New sign-in will be persisted with session persistence.
    			return signInWithEmailAndPassword(auth, inputFieldData.email, inputFieldData.password)
				.then((userCredential) => {
				  // Signed in 
				  const user = userCredential.user;
				  // ...
				  window.location.assign("userProfile.js");
				  alert("Login Succesful");
				})
				.catch((error) => {
				  alert("Incorrect Email or Password");
				  const errorCode = error.code;
				  const errorMessage = error.message;
				});
  			})
  			.catch((error) => {
    			// Handle Errors here.
    			const errorCode = error.code;
    			const errorMessage = error.message;
  			});
            signInWithEmailAndPassword(auth, inputFieldData.email, inputFieldData.password)
  			.then((userCredential) => {
    			// Signed in 
    			const user = userCredential.user;
    			// ...
				window.location.assign("userProfile.js");
				alert("Login Succesful");
  			})
  			.catch((error) => {
				alert("Incorrect Email or Password");
    			const errorCode = error.code;
    			const errorMessage = error.message;
  			});
        }
	}

	return (
		<body>
		<div>
			<usernameInput/>
			<header>
                <a href="https://www.csus.edu/">
					<img src="../Images/Sacramento-State-University-logo_stacked.png"/>
				</a>
            </header>
			<form id="loginForm">
				<div id="loginContents" style={{ textAlign: "center" }}>
					<div id="loginError">
						<label>username/password incorrect</label></div>
					<div>
						<label> <b>Email:  </b>
							<input
                                type="email"
                                name="email"
                                maxLength={40}
                                value={inputFieldData.email}
                                onChange={handleChange}
                                placeholder="email@csus.edu"
                            />
						</label>
						<div></div>
						<br />
						<label> <b>Password:  </b>
							<input
								type="password"
								name="password"
								minLength={8}
								maxLength={16}
								value={inputFieldData.password}
								onChange={handleChange}
								placeholder="Password"
								required
							/>
						</label>
						<div></div>
						<br />
						<button 
							onClick={handleSubmit}
							type="submit">Login</button>
						<br />
					</div>
				</div>
				<p>
                    <a 
						id="CreateAccount"
						href="CreateAccount.js" 
						style={{ textAlign: "left", alignItems: "left", marginRight: 250}}>
						Create Account
					</a>
					<a 
                    	id="ForgotPassword" 
                    	href="ForgotPassword.js"
                    	style={{ textAlign: "right", alignItems: "right", marginLeft: 250}}> 
                    	Forgot Password?
                	</a>
                </p>
			</form>
		</div>
		</body>
	);
}

export default LoginPortal;