import React, { useState, useContext, createContext } from "react";
import './Webpages/CSS/TeamList.css';

const FilterContext = createContext();

export default function TeamList(){

    //TODO: add items from the database into the list
    //Also probably have to redo how the items are added, this probably doesnt work
    // const [items, setItems] = useState([]);
    // const addItem = (newItem) => {
    //     setItems([...items, newItem]);
    // };

    const [filters, setFilters] = useState({
        teamName: '',
        memberCount: '',
        hasAdvisor: '',
    });

    const handleFilterChange = (e) => {
        const{name, value} = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters, [name]: value,
        }));
    }

    //placeholder for testing
    const teams = [
        {
            id: 0,
            teamName: "Team John",
            memberCount: "6",
            hasAdvisor: "true",
        },
        {
            id: 1,
            teamName: "Team Bob",
            memberCount: "8",
            hasAdvisor: "true",
        },
        {
            id: 2,
            teamName: "Team Sally",
            memberCount: "3",
            hasAdvisor: "false",
        },
        {
            id: 3,
            teamName: "Team Greg",
            memberCount: "7",
            hasAdvisor: "false",
        },
    ];

    //filter teams
    const filteredTeams = teams.filter((team) => {
        return(
            (team.teamName.toLowerCase().includes(filters.teamName.toLowerCase()))
            &&
            (filters.memberCount === '' || team.memberCount === filters.memberCount)
            &&
            (filters.hasAdvisor === '' || team.hasAdvisor === filters.hasAdvisor)
        );
    });


    return(
        <FilterContext.Provider value={{
            filters, handleFilterChange
        }}>
            <div className="container">
                <h1 id="filterHeader">Filters</h1>
                <FilterControls/>
                <div className="filterTableDiv">
                    <table id="filterTable">
                        <thead>
                            <tr id="filterTableRow">
                                <th id="filterTableHeader">Team Name</th>
                                <th id="filterTableHeader">Member Count</th>
                                <th id="filterTableHeader">Has Advisor</th>
                            </tr>
                        </thead>
                        {filteredTeams.map((team) => {
                            return(
                                <tbody>
                                    <tr id="filterTableRow" key={team.id}>
                                        <td id="filterTableItem">{team.teamName}</td>
                                        <td id="filterTableItem">{team.memberCount}</td>
                                        <td id="filterTableItem">{team.hasAdvisor}</td>
                                    </tr>
                                </tbody>
                            )
                        })}
                    </table>
                </div>
            </div>
        </FilterContext.Provider>

    );

}

const FilterControls = () => {
    const {filters, handleFilterChange} = useContext(FilterContext);
    return(
        <div className="filters">
            <label id="filterLabel">
                <input
                    name="teamName"
                    type="text"
                    id="filterTextbox"
                    value={filters.teamName}
                    onChange={handleFilterChange}
                    placeholder="Search by Team Name"
                />
            </label>
            <label id="filterLabel">
                <select
                    name="memberCount"
                    id="filterDropdownMenu"
                    value={filters.memberCount}
                    onChange={handleFilterChange}
                >
                    <option value="">Any Member Amount</option>
                    <option value="1">1 Member</option>
                    <option value="2">2 Members</option>
                    <option value="3">3 Members</option>
                    <option value="4">4 Members</option>
                    <option value="5">5 Members</option>
                    <option value="6">6 Members</option>
                    <option value="7">7 Members</option>
                    <option value="8">8 Members</option>
                    <option value="9">9 Members</option>
                    <option value="10">10 Members</option>
                </select>
            </label>
            <label id="filterLabel">
                <select
                    name="hasAdvisor"
                    id="filterDropdownMenu"
                    value={filters.hasAdvisor}
                    onChange={handleFilterChange}
                >
                    <option value="">Any Advisor Status</option>
                    <option value="true">Has Advisor</option>
                    <option value="false">No Advisor</option>
                </select>
            </label>
        </div>
    )
}