import React, { useState } from "react"
import styled from "styled-components";
import {collection, addDoc} from "firebase/firestore";
import {db, auth} from "./Firebase/firebase";
import { createTeamCode } from "./teamCodeFunctions";

const Popup_Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Popup_Inner_Container = styled.div`
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #FFF;
`;

//style when ready to place wherever
const Open_Button = styled.button`

`;

const Close_Button = styled.button`
    position: absolute;
    top: 16px;
    right: 16px;
`;

export default function CreateTeamPopup() {
    const [inputFieldData, setInputFieldData] = useState({  // Holds the inputs
        teamName: '',
    });

    const [popupState, setPopupState] = useState(false)

    function togglePopup(){
        setPopupState(!popupState);
    }

    const [inputFieldErrors, SetInputFieldErrors] = useState({}); // Store any field errors

    // const user= auth.currentUser;

    //temp user
    const user={
        email: "testemail@csus.edu",
        username: "testUsername",
    }


    const createNewTeam = async (teamName) => {
        try {
            // if(user){
                // Store in DB
                let teamCode = createTeamCode();

                console.log("Team code,", teamCode);
                const teamMemberUsername = {
                    [user.email]: user.username
                }
                

                const docRef = await addDoc(collection(db, "teams"), {
                    teamName: teamName,
                    teamJoinCode: teamCode,
                    teamLead: user.email,
                    teamMemberEmail: [user.email],
                    teamMemberUsername: teamMemberUsername,
                })
                
                console.log("Document written with ID: ", docRef.id);
                alert("Team created successfully!");
            // }
        } catch (error) {
            console.error("Failed to create team: ", error);
            alert("Failed to create team.");
        }
    };

    const handleSubmit = async (event) => { // Function handles the submission to check if its valid
        event.preventDefault();
        let errors = {};
        let isFormValid = true;
        let isCreated = false;
    
        Object.keys(inputFieldData).forEach(key => { //Goes over inputs to check if filled
          if (!inputFieldData[key]) {
            errors[key] = '*This field is required';
            isFormValid = false;
          } 
          else { // Checks if validateField was good
            // const fieldError = validateField(key, inputFieldData[key]);
            // if (fieldError) {
            //   errors[key] = fieldError;
            //   isFormValid = false;
            // } 
          }
        });
    
    
        SetInputFieldErrors(errors);
        if (isFormValid) {
            console.log("Form is valid", inputFieldData);
            //write to database here
            await createNewTeam(
                inputFieldData.teamName,
            ).catch(

            );
        }
    };
    
    const handleChange = (event) => { // Function updates the new/current inputs
        const { name, value } = event.target;
        setInputFieldData(prev => ({ ...prev, [name]: value }));
    };

    return(
        <>
        <Open_Button onClick={togglePopup}>Open</Open_Button>
        {popupState &&(
            <Popup_Container>
                <Popup_Inner_Container>
                    <Close_Button onClick={togglePopup}>Close</Close_Button>
                    <h1>Team Create</h1>
                    <h4>To create a team, enter the team name in the box below.</h4>
                    <form id="createTeamForm" onSubmit={handleSubmit}>
                        <div id="createTeamFormContents">
                            <label>
                                <b>Team Name</b>
                                <input
                                    type="text"
                                    name="teamName"
                                    minLength={3}
                                    maxLength={16}
                                    value={inputFieldData.teamName}
                                    onChange={handleChange}
                                    placeholder="Team Name"
                                />
                            </label>
                            <br/>
                            <button onClick={handleSubmit} type="submit">Create Team</button>
                        </div>
                    </form>
                </Popup_Inner_Container>
            </Popup_Container>
        )}   
        </>
    );
}