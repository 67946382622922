import "./homepage.css";
import "./userPage.css";
import React, { useState, useEffect } from "react";
import defaultProfilePic from "./images/default_profile_pic.jpg";
import { getAuth } from "firebase/auth";

const auth = getAuth();
const user = auth.currentUser;

function UserPage() {
	const [iframeSrc, setIframeSrc] = useState("http://example.com");
	const [username, setUsername] = useState("");
	const [profilePicture, setProfilePicture] = useState("");

	if (user) {
		// User is signed in, see docs for a list of available properties
		// https://firebase.google.com/docs/reference/js/auth.user
		// ...
	} else {
		// No user is signed in.
		
	}

	useEffect(() => {
		function adjustIframeHeight() {
			const iframe = document.getElementById("iframePage");
			if (iframe) {
				iframe.style.height =
					iframe.contentWindow.document.body.scrollHeight + "px";
			}
		}

		window.addEventListener("resize", adjustIframeHeight);
		adjustIframeHeight();
	}, []);

	//set default username and profile picture
	if (!username) {
		setUsername("Default Username");
	}
	if (!profilePicture) {
		setProfilePicture(defaultProfilePic);
	}

	return (
		<div className="grid">
			<div className="row">
				<div id="profilePic" className="column1">
					<img
						src={profilePicture}
						alt=""
						style={{ width: "150px", height: "150px" }}
					/>
				</div>
				<div id="userName" className="column2">
					<p id="profileName">{username}</p>
				</div>
			</div>
			<div className="row">
				<div id="userMenu" className="column1">
					<ul>
						<li>
							<button onClick={() => setIframeSrc("/userProfile")}>
								Profile
							</button>
						</li>
						<li>
							<button onClick={() => setIframeSrc("/userSettings")}>
								Settings
							</button>
						</li>
						<li>
							<button onClick={() => {setIframeSrc("/Calendar")}}>
								MyCalendar
							</button>
						</li>
						<li>
							<button onClick={() => setIframeSrc("/userMessages")}>
								Messages
							</button>
						</li>
						<li>
							<button onClick={() => setIframeSrc("/userClasses")}>
								Classes
							</button>
						</li>
						<li>
							<button onClick={() => setIframeSrc("/ProfileLabAdvisorTab")}>
								Lab Advisor
							</button>
						</li>
					</ul>
				</div>
				<div id="iFrame" className="column2">
					<iframe
						id="iframePage"
						src={iframeSrc}
						title="Iframe Page"
						style={{
							width: "100%",
							height: "100%",
							minHeight: "40vh",
							border: "none",
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default UserPage;

