import React from "react";
import * as RxIcons from "react-icons/rx";

/*
How to add a item to the sidebar
{
    title: "insert title"
    path: "insert path"
    icon: <pass it an icon from react-icons/> 
    icons can be found here https://react-icons.github.io/react-icons/

    if it is a dropdown, include as well
    iconClosed: <RxIcons.RxTriangleDown />, (icons subject to change)
    iconOpened: <RxIcons.RxTriangleUp />,

    subNav is where your child elements under the dropdown will be listed
    subNav: [
        {
            title: "insert title"
            path: "insert path"
            icon: <pass it an icon from react-icons/> 
        }
    repeat for as many items as you want in the dropdown
    ]
}
*/




export const sidebarData =[
    {
        title: "Login Portals",
        path: "#",
        icon: <RxIcons.RxAvatar />,
        iconClosed: <RxIcons.RxTriangleDown />,
        iconOpened: <RxIcons.RxTriangleUp />,

        subNav: [
            {
                title: "Login Portal",
                path: "/LoginPortal.js",
                icon: <RxIcons.RxBackpack />,
            },
        ],
    },
    {
        title: "Create Account",
        path: "/CreateAccount.js",
        icon: <RxIcons.RxBookmark />,
    },
    {
        title: "Messages",
        path: "#",
        icon: <RxIcons.RxCube />,
        iconClosed: <RxIcons.RxTriangleDown />,
        iconOpened: <RxIcons.RxTriangleUp />,

        subNav: [
            {
                title: "Recent Messages",
                path: "./recentMessages.js",
                icon: <RxIcons.RxBell />,
            },

            {
                title: "Message Center",
                path: "/",
                icon: <RxIcons.RxArchive />,
            },
        ],
    },


];