import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserProvider } from "./UserContext.js";

import Homepage from "./Webpages/homePage.js";
import LoginPortal from "./LoginPortal.js";
import CreateAccountPage from "./CreateAccount.js";
import reportWebVitals from "./reportWebVitals";
import UserPage from "./userPage.js";
import UserSettings from "./userSettings.js";
import UserClasses from "./userClasses.js";
import UserMessages from "./userMessages.js";
import UserProfile from "./userProfile.js";
import UserAdvisors from "./userAdvisors.js";
import ProfileLabAdvisorTab from "./ProfileLabAdvisorTab.js";
import Calendar from "./Calendar/Calendar.js";
import Sidebar from "./Sidebar.js";
import RecentMessages from "./recentMessages.js";
import ProfessorCalendar from "./Calendar/professorCalendar.js";

import TestCalendar from "./Calendar/testCalendar.js";
import HomepageTest from "./Webpages/homepageTest.js";

import HomePageUser from "./Webpages/homePage.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<UserProvider>
			<Router>
				<Sidebar />
				<main>
					<Routes>
						<Route path="/" element={<LoginPortal />} />
						<Route path="/LoginPortal.js" element={<LoginPortal />} />
						<Route path="/CreateAccount.js" element={<CreateAccountPage />} />
						<Route path="/userPage" element={<UserPage />} />
						<Route path="/userSettings" element={<UserSettings />} />
						<Route path="/userClasses" element={<UserClasses />} />
						<Route path="/userMessages" element={<UserMessages />} />
						<Route path="/userAdvisors" element={<UserAdvisors />} />
						<Route path="/userProfile" element={<UserProfile />} />
						<Route path="/ProfileLabAdvisorTab" element={<ProfileLabAdvisorTab />}/>
						<Route path="/Calendar" element={<Calendar />} />
						<Route path="/ProfessorCalendar" element={<ProfessorCalendar />} />
						<Route path="/recentMessages.js" element= {<RecentMessages />} />
						<Route path="/NewHomePage" element={<HomePageUser />} />

						<Route path="/HomePageTest" element={<HomepageTest />} />
						<Route path="/TestCalendar" element={<TestCalendar />} />
					</Routes>
				</main>
			</Router>
		</UserProvider>
	</React.StrictMode>
);

reportWebVitals();
