import React, { useState, useRef, useEffect, useContext } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./userCalendar.css";
import { db } from "../Firebase/firebase";
import {
  getDocs,
  addDoc,
  deleteDoc,
  collection,
  query,
  collectionGroup,
  doc,
} from "firebase/firestore";
import { UserContext } from "../UserContext"; 

export default function RoleBasedCalendar() {
    const calendarRef = useRef(null);
    const [modal, setModal] = useState(false);
    const [availability, setAvailability] = useState([]);
    const [events, setEvents] = useState([]);
    const [title, setTitle] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const { currentUser, role, loading } = useContext(UserContext); // Use UserContext to get currentUser and role

    console.log("Current User in Calendar Component:", currentUser);
    console.log("Role in Calendar Component:", role);

    // Fetch users from Firestore
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersData = await getDocs(collection(db, "users"));
                const usersList = usersData.docs.map((doc) => ({
                    uid: doc.data().uid,
                    name: `${doc.data().firstName} ${doc.data().lastName}`,
            }));
            setAllUsers(usersList);
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };
        fetchUsers();
    }, []);

    // Fetch availability and events from Firestore
    useEffect(() => {
        if (!currentUser || !role) {
            console.log("Cannot fetch events because currentUser or role is not defined.");
            return;
        }
  
        const getEvents = async () => {
            try {
                
                console.log("Fetching events for role:", role);
  
                const availabilitiesData = await getDocs(collection(db, "availabilities"));
                const availabilities = availabilitiesData.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                    color: "green",
                    extendedProps: {
                        type: "availability",
                    },
                }));

                let meetingsQuery;
                if (role === "Professor") {
                    meetingsQuery = collectionGroup(db, "meetings");
                } else {
                    meetingsQuery = collection(db, "users", currentUser.uid, "meetings");
                }
  
                const meetingsData = await getDocs(meetingsQuery);
                const meetings = meetingsData.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                    color: "blue",
                    extendedProps: {
                        type: "meeting",
                    },
                }));
  
                setAvailability(availabilities);
                setEvents(meetings);
            } catch (error) {
                console.error("Error fetching events:", error);
            }
        };
        getEvents();
    }, [currentUser, role]);

    // Check if the selected time slot is available
    const isTimeSlotAvailable = (start, end) => {
        return availability.some(
            (event) =>
                event.extendedProps.type === "availability" &&
                new Date(start) >= new Date(event.start) &&
                new Date(end) <= new Date(event.end)
        );
    };

    // Open modal
    const handleCreateEventClick = (selectInfo) => {
        const { startStr, endStr } = selectInfo;
        setSelectedEvent(null);
        setStart(startStr);
        setEnd(endStr);
        setSelectedUsers([]);
        setModal(true);
    };

    // Handle availability submission and add to Firestore
    const handleAvailabilitySubmit = async () => {
        console.log("Availability submit handler called. Role:", role);
        if (role !== "Professor") return;

    const newAvailability = {
        title: "Available Slot",
        start,
        end,
        allDay: false,
        color: "green",
    };

    try {
        await addDoc(collection(db, "availabilities"), newAvailability);
        setAvailability([...availability, newAvailability]);
        alert("Availability saved to database!");
        setModal(false);
    } catch (error) {
        console.error("Error saving availability:", error);
        alert("Failed to save availability.");
    }
    };

    // Handle meeting submission and add to Firestore
    const handleMeetingSubmit = async (event) => {
        event.preventDefault();
        console.log("Meeting submit handler called. Role:", role);
        if (role !== "Student") {
            alert("Role not defined or not permitted to create a meeting. Only students can create meetings.");
            return;
        }

        if (!isTimeSlotAvailable(start, end)) {
            alert("The selected time slot is not available. Please choose an available slot.");
            return;
        }

        const newEvent = {
            title,
            start,
            end,
            attendees: [currentUser.uid, ...selectedUsers],
            creator: currentUser.uid,
        };

        try {
            await addDoc(collection(db, "users", currentUser.uid, "meetings"), newEvent);
            for (const userId of selectedUsers) {
                await addDoc(collection(db, "users", userId, "meetings"), newEvent);
            }

            setEvents([
                ...events,
                {
                    ...newEvent,
                    color: "blue",
                    extendedProps: {
                        type: "meeting",
                    },
                },
            ]);
            alert("Meeting saved to database!");
            setModal(false);
        } catch (error) {
            console.error("Error saving meeting:", error);
            alert("Failed to save meeting.");
        }
    };

    // Handle event click
    const handleEventClick = (clickInfo) => {
        if (clickInfo.event.extendedProps.type === "availability" && role !== "Professor") {
            alert("You cannot modify or delete availability slots.");
            return;
        }
        setSelectedEvent(clickInfo.event);
        setModal(true);
    };

    // Handle meeting deletion
    const handleDeleteEvent = async () => {
        if (!selectedEvent) return;

        if (
            window.confirm(`Are you sure you want to delete the event '${selectedEvent.title}'?`)
        ) {
            try {
                await deleteDoc(doc(db, "users", currentUser.uid, "meetings", selectedEvent.id));
                setEvents(events.filter((event) => event.id !== selectedEvent.id));
                selectedEvent.remove();
                alert("Event deleted successfully!");
                setModal(false);
            } catch (error) {
                console.error("Error deleting event:", error);
                alert("Failed to delete the event.");
            }
        }
    };

    return (
        <div className="container">
            {loading ? (
                <div>Loading...</div>
            ) : (
                <div id="calendar">
                    <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{
                            start:
                                role === "Professor"
                                    ? "prev,next createAvailabilityButton"
                                    : "prev,next createEventButton",
                            center: "title",
                            end: "today,dayGridMonth,timeGridWeek,timeGridDay",
                        }}
                        customButtons={{
                            createAvailabilityButton: {
                                text: "Set Availability",
                                click: handleCreateEventClick,
                            },
                            createEventButton: {
                                text: "Create Event",
                                click: handleCreateEventClick,
                            },
                        }}
                        initialView="dayGridMonth"
                        events={[...availability, ...events]}
                        eventClick={handleEventClick}
                        selectable={true}
                    />

                    {modal && (
                        <div className="custom-modal">
                            <div className="custom-modal-content">
                                <span className="close" onClick={() => setModal(false)}>
                                    &times;
                                </span>
                                {selectedEvent ? (
                                    <div className="event-details">
                                        <h2 className="event-title">Event Details</h2>
                                        <p className="event-text">
                                            <strong>Title:</strong> {selectedEvent.title}
                                        </p>
                                        <p className="event-text">
                                            <strong>Start:</strong> {new Date(selectedEvent.start).toLocaleString()}
                                        </p>
                                        <p className="event-text">
                                            <strong>End:</strong> {new Date(selectedEvent.end).toLocaleString()}
                                        </p>
                                        <button className="delete-button" onClick={handleDeleteEvent}>
                                            Delete Event
                                        </button>
                                    </div>
                                ) : role === "Professor" ? (
                                    <>
                                        <h2>Set Availability</h2>
                                        <div className="form-group">
                                            <label>Start</label>
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                value={start}
                                                onChange={(e) => setStart(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>End</label>
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                value={end}
                                                onChange={(e) => setEnd(e.target.value)}
                                            />
                                        </div>
                                        <button className="btn" onClick={handleAvailabilitySubmit}>
                                            Save Availability
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <div className="form-group">
                                            <label htmlFor="meetingTitle">Meeting Title</label>
                                            <input
                                                type="text"
                                                id="meetingTitle"
                                                name="meetingTitle"
                                                className="form-control"
                                                placeholder="e.g. Lab Advisor Meeting"
                                                value={title}
                                                onChange={(e) => setTitle(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="start">Start</label>
                                            <input
                                                id="start"
                                                type="datetime-local"
                                                name="start"
                                                className="form-control"
                                                value={start}
                                                onChange={(e) => setStart(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="end">End</label>
                                            <input
                                                type="datetime-local"
                                                id="end"
                                                name="end"
                                                className="form-control"
                                                value={end}
                                                onChange={(e) => setEnd(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Attendees</label>
                                            <select
                                                className="form-control"
                                                multiple
                                                value={selectedUsers}
                                                onChange={(e) => {
                                                    const selectedOptions = Array.from(
                                                        e.target.selectedOptions,
                                                        (option) => option.value
                                                    );
                                                    setSelectedUsers(selectedOptions);
                                                }}
                                                style={{ height: "100px", overflowY: "scroll" }}
                                            >
                                                {allUsers
                                                    .filter((user) => currentUser && user.uid !== currentUser.uid)
                                                    .map((user) => (
                                                        <option key={user.uid} value={user.uid}>
                                                            {user.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        <button className="btn" onClick={handleMeetingSubmit}>
                                            Save Event
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}