/**
 * Asynchronously retrieves the user ID from the browser cookies.
 *
 * This function searches the document's cookies for a cookie named "userID"
 * and returns its value. If the "userID" cookie is not found, it logs an error
 * message to the console.
 *
 * @returns {Promise<string|undefined>} A promise that resolves to the user ID
 * if found, otherwise undefined.
 */
async function getUserIdFromCookie() {
	const userIDFromCookies = document.cookie
		.split("; ")
		.find((row) => row.startsWith("userID="))
		?.split("=")[1];
	if (userIDFromCookies) {
		return userIDFromCookies;
	} else {
		console.error("User ID not found in cookies");
	}
}

export default getUserIdFromCookie;