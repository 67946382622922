import React, { useState } from "react"
import styled from "styled-components";
import {handleTeamCodeDatabase } from "./teamCodeFunctions";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { db } from "./Firebase/firebase";


const Popup_Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Popup_Inner_Container = styled.div`
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #FFF;
`;

//style when ready to place wherever
const Open_Button = styled.button`

`;

const Close_Button = styled.button`
    position: absolute;
    top: 16px;
    right: 16px;
`;

export default function TeamInvitePopup(){
    const [inputFieldData, setInputFieldData] = useState('');

    const [popupState, setPopupState] = useState(false)

    function togglePopup(){
        setPopupState(!popupState);
    }

    //temp user
    const user={
        email: "testAddEmail@csus.edu",
        username: "testAddUsername",
    }


    const handleSubmit = async (event) => {
        event.preventDefault();

        await handleTeamCodeDatabase(inputFieldData);
    }

    const handleChange = (event) => { 
        setInputFieldData(event.target.value);
    };

    
    return(
        <>
        <Open_Button onClick={togglePopup}>Open</Open_Button>
        {popupState &&(
            <Popup_Container>
                <Popup_Inner_Container>
                    <Close_Button onClick={togglePopup}>Close</Close_Button>
                        <h1>Team Join</h1>
                        <h4>To join a team, enter the team join code in the box below.</h4>
                        <form>
                            <label>
                                <input 
                                    type="text"
                                    name="joinCode"
                                    pattern="[A-Za-z0-9]+"
                                    minLength={10}
                                    maxLength={10}
                                    value={inputFieldData}
                                    onChange={handleChange}
                                    placeholder="Join Code"
                                />
                            </label>
                            <button type="submit" onClick={handleSubmit}>Join</button>
                        </form>
                </Popup_Inner_Container>
            </Popup_Container>
        )}   
        </>
    );
}