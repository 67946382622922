import React, { useEffect } from "react";
import styled from "styled-components";
//TODO: import firebase
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
import TeamList from "./TeamList";
import TeamInvitePopup from "./TeamInvite";
import './Webpages/CSS/RecentMessages.css';
import CreateTeamPopup from "./teamCreate";


//const messaging = getMessaging(firebaseApp);

//styling
const Container = styled.div`
    margin: auto;
    text-allign: center;
    width: 90%;
    background-color: white;
    justify-content: center;
    padding-bottom: 10px;
    padding-right: 10px;

`;



export default function RecentMessages(){
    
    // const [notif, setNotif] = React.useState([]);
    // const [sentMessage, setsentMessage] = React.useState([]);
    // const [recievedMessage, setrecievedMessage] = React.useState([]);
    
    //TODO: fetch notifications
    //TODO: fetch sent messages
    //TODO: fetch recieved messages

    //temp data for testing
    
    const messages = [
        {
            messageType: "Notification",
            messageContent: "Notif 1",
        },
        {
            messageType: "Notification",
            messageContent: "Notif 2",
        },
        {
            messageType: "Notification",
            messageContent: "Notif 3",
        },
        {
            messageType: "Sent",
            messageContent: "Sent 1",
        },
        {
            messageType: "Sent",
            messageContent: "Sent 2",
        },
        {
            messageType: "Sent",
            messageContent: "Sent 3",
        },
        {
            messageType: "Recieved",
            messageContent: "Recieved 1",
        },
        {
            messageType: "Recieved",
            messageContent: "Recieved 2",
        },
        {
            messageType: "Recieved",
            messageContent: "Recieved 3",
        },
        {
            messageType: "Recieved",
            messageContent: "Recieved 4",
        },

    ]

    // const addNotif = (newNotif) => {
    //     setNotif([...notif, ...newNotif]);
    // }

    // const addSent = (newSent) => {
    //     setsentMessage([...sentMessage, ...newSent]);
    // }

    // const addRecieved = (newRecived) => {
    //     setrecievedMessage([...recievedMessage, ...newRecived]);
    // }

    // function sortMessages(){
    //     const sortNotif = messages.filter(message => 
    //         message.messageType === "Notification"
    //     );
    //     addNotif(sortNotif);

    //     const sortSent = messages.filter(message => 
    //         message.messageType === "Sent"
    //     );
    //     addSent(sortSent);

    //     const sortRecieved = messages.filter(message => 
    //         message.messageType === "Recieved"
    //     );
    //     addRecieved(sortRecieved);
    // }

    // useEffect(() => {
    //     sortMessages();
    // }, []);

    const sortNotif = messages.filter(message => 
        message.messageType === "Notification"
    );

    const sortSent = messages.filter(message => 
        message.messageType === "Sent"
    );

    const sortRecieved = messages.filter(message => 
        message.messageType === "Recieved"
    );

    

    const printMessages = (messageList) => {
        return(
            <table id="messageTable">
                <thead>
                    <tr id="messageTableRow">
                        <th id="messageTableHead">Message Type</th>
                        <th id="messageTableHead">Message Content</th>
                    </tr>
                </thead>
            {messageList.map((item, index) => {
                return(
                    <tbody>
                        <tr id="messageTableRow" key={index}>
                            <td id="messageTableData">{item.messageType}</td>
                            <td id="messageTableData">{item.messageContent}</td>
                        </tr>
                    </tbody>
                )
            })}
            </table>
        )
    }



    
    return(
        <>
        {/* <Container>
            <h1>Notifications</h1>
            {printMessages(sortNotif)}
        </Container>
        <Container>
            <h1>Recently Sent</h1>
            {printMessages(sortSent)}
        </Container>
        <Container>
            <h1>Recently Recieved</h1>
            {printMessages(sortRecieved)}
        </Container> */}
        <div className="messageContainer">
            <h1 className="messageHeader">Notifications</h1>
            {printMessages(sortNotif)}

            <h1 className="messageHeader">Recently Sent</h1>
            {printMessages(sortSent)}

            <h1 className="messageHeader">Recently Recieved</h1>
            {printMessages(sortRecieved)}
        </div>

        {/* just to have the module somewhere to look at */}
        <TeamList></TeamList>
        <TeamInvitePopup></TeamInvitePopup>
        <CreateTeamPopup></CreateTeamPopup>
        </>
    )
}